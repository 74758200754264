import ReactLoading from 'react-loading';
import { ReactElement } from 'react';
import clsx from 'clsx';
import Style from './Style.module.css';
import Spin from './Spin';

export enum Color {
  gray = 'gray',
  primary = 'primary',
}

export interface props {
  className?: string;
  containerClassName?: string;
  color?: Color;
}


function Loading({
  className,
  containerClassName,
  color = Color.primary,
}: props): ReactElement {
  return (
    <div
      className={clsx(
        `fixed left-0 right-0 z-[1040] flex h-[100vh] items-center justify-center `,
        containerClassName,
        Style['containerLoading']
      )}
    >
      <Spin className={clsx('h-12 w-12', className, Style['item-' + color])} />
    </div>
  );
}
export default Loading;
