import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import Skeleton from 'common/components/npm/skeleton/Skeleton';
import { subText, isEmpty } from 'common/utils/methods';
import Dropdown from './Dropdown';
import { useProfileContext } from 'common/context/profile-context';
import clsx from 'clsx';
import Image from 'common/components/npm/lazy-load-image/Image';
import { GrDown } from 'react-icons/gr';
import Button from 'common/components/tags/button/Button';
import { useAuthContext } from 'common/context/auth-context';

const ProfileInfo = ({
  pendingRequestsCount,
  showDropdown,
  setShowDropdown,
}: {
  pendingRequestsCount: number;
  showDropdown: boolean;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
}) => {
  const profileCtx = useProfileContext();
  const authCtx = useAuthContext();
  const [isLogin, setIsLogin] = useState<boolean>(false);

  useEffect(() => {
    setIsLogin(Boolean(authCtx?.isLogin));
  }, [authCtx?.isLogin]);

  const handleProfile = () => {
    if (!profileCtx?.data) return null;
    setShowDropdown((prevState) => !prevState);
  };

  return (
    <div>
      {isLogin ? (
        <div
          className={clsx(
            'relative hidden items-center md:flex',
            profileCtx?.data && 'cursor-pointer'
          )}
          onClick={handleProfile}
          id='profileDropdown'
        >
          {!profileCtx?.data ? (
            <Skeleton className='h-6 w-[10rem]' />
          ) : (
            <span className='font-medium normal-case text-black md:py-[2.2rem] md:text-[1.2rem] lg:text-[1.4rem] xl:py-[2.8rem] xl:text-[1.6rem]'>
              {isEmpty(profileCtx.data.name)
                ? subText(profileCtx?.data?.email?.split('@')[0], 10)
                : subText(profileCtx.data.name, 10)}
            </span>
          )}
          <GrDown color={'#636464'} className='mr-2 ml-1' />
          {!profileCtx?.data ? (
            <Skeleton className='h-10 w-10 rounded-full' />
          ) : (
            <div className='relative'>
              <Image
                src={
                  profileCtx?.data?.avatar?.downloadUrl ||
                  '/img/default-profile-icon.jpg'
                }
                alt={profileCtx.data.name}
                className='h-10 w-10 rounded-full object-cover'
                placeholderSrc={'/img/default-profile-icon.jpg'}
              />
              {Boolean(pendingRequestsCount) && (
                <span className='absolute -right-[2px] h-3 w-3 rounded-full bg-primary' />
              )}
            </div>
          )}
          {showDropdown && (
            <Dropdown pendingRequestsCount={pendingRequestsCount} />
          )}
        </div>
      ) : (
        <>
          <Button
            label='Sign Up'
            type='link'
            path='/signup'
            className='hidden md:inline-block lg:mr-[0.8rem]'
          />
          <Button
            label='Sign in'
            type='link'
            path='/signin'
            variant='outLine'
            className='hidden lg:inline-block'
          />
        </>
      )}
    </div>
  );
};

export default ProfileInfo;
