import React from 'react';
import clsx from 'clsx';
import style from './Style.module.css';

const Layer = ({
  active,
  onClick,
  className,
  varint,
}: {
  active: boolean;
  onClick: () => void;
  className?: string;
  varint?: 'dark' | 'light';
}) => {
  return (
    <div
      className={clsx(
        style['layer'],
        varint === 'dark' && style['layer-dark'],
        className,
        active ? 'fixed top-0 left-0 right-0 bottom-0 ' : 'hidden'
      )}
      onClick={onClick}
    ></div>
  );
};

export default Layer;
