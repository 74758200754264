import React from 'react';
import MenuLink from './DropdownItem';
import { useModalContext } from 'common/context/modal-context';

const NavItems = ({
  pendingRequestsCount,
}: {
  pendingRequestsCount: number;
}) => {
  const modalCtx = useModalContext();
  return (
    <div className='border-card absolute top-[6.5rem] right-0 hidden w-[38rem] rounded-3 bg-white md:block'>
      <ul>
        <MenuLink href='/profile' iconClassName='about-us' title='Profile' />
        <MenuLink
          href='/dashboard'
          title='dashboard'
          iconClassName='dashboard'
        />
        <MenuLink
          href='/properties'
          title='my Properties'
          iconClassName='terms'
        />
        <MenuLink
          href='/sent-requests'
          title='requests'
          iconClassName='request'
          badge={pendingRequestsCount}
        />
        <MenuLink
          href='/saved-properties'
          title='Saved'
          iconClassName='saved'
        />
        <li className='p-5' onClick={() => modalCtx?.toggleModal('signOut')}>
          <div className='flex items-center text-danger'>
            <i className='apollon-sign-out text-[1.9rem]' />
            <p className='ml-2 text-[1.6rem] font-medium'>sign out</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(NavItems);
