import React, { useState, useEffect } from 'react';
import Link from 'common/components/tags/link';
import { IoIosArrowUp } from 'react-icons/io';
import clsx from 'clsx';
import Typography from '../tags/typography/Typography';
import { useAuthContext } from 'common/context/auth-context';

export interface props {
  className?: string;
}

const Footer = ({ className }: props) => {
  const [isLogin, setIsLogin] = useState<boolean | undefined | null>(false);
  const authCtx = useAuthContext();

  useEffect(() => {
    setIsLogin(authCtx?.isLogin);
  }, [authCtx?.isLogin]);

  const scrollTo = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <footer className={clsx('rounded-t-[3.2rem] bg-white', className)}>
      <div className='container'>
        <div className='relative'>
          <div className='absolute top-[-27px] right-[calc(50%-2.3rem)] md:left-0'>
            <button
              onClick={scrollTo}
              className='flex h-[4.6rem] w-[4.6rem] items-center justify-center rounded-full 
        bg-blue10 md:h-[5.2rem] md:w-[5.2rem]'
            >
              <IoIosArrowUp color='#006FFD' size={23} />
            </button>
          </div>
        </div>

        <div className='flex justify-center pb-8 pt-12 md:justify-start'>
          <img src='/img/logo.svg' alt='logo' className='h-[6rem] w-[20rem]' />
        </div>
        <div className='grid grid-cols-3 border-b border-t border-gray4 pt-[8rem] pb-[1.2rem] md:py-[2.4rem] lg:py-[3rem]'>
          <div className='col-span-3 md:col-span-1'>
            <Typography variant='h4-card' className='align pb-2'>
              Apollon
            </Typography>
            <ul>
              <ItemLink title='Search for rent' path='/find-property' />
              {!isLogin && <ItemLink title='Create Account' path='/signup' />}
              <ItemLink title='Download Apps' path='/apps' />
              <ItemLink title='Contact Us' path='/contact' />
              <ItemLink title='About Us' path='/about' />
              {/* <ItemLink title="Careers" path="/" /> */}
            </ul>
          </div>
          <div className='col-span-3 pt-[4rem] md:col-span-1 md:pt-0'>
            <Typography variant='h4-card' className='align pb-2'>
              Resources
            </Typography>
            <ul>
              <ItemLink title='Blog' path='/blog' />
              <ItemLink title='Help Centre' path='/help/categories' />
              <ItemLink title='change log' path='/changelog' />
              {/* <ItemLink title="Cookies" path="/" /> */}
            </ul>
          </div>
          <div className='col-span-3 pt-[4rem] md:col-span-1 md:pt-0'>
            <Typography variant='h4-card' className='align pb-2'>
              Legal
            </Typography>
            <ul>
              <ItemLink
                className='normal-case'
                title='Terms & Conditions'
                path='/terms'
              />
              {/* <ItemLink title="trust Information" path="/trust" /> */}
              <ItemLink title='privacy policy' path='/privacy' />
              <ItemLink title='Trust' path='/trust' />
              <ItemLink title='Cookies' path='/cookies' />
            </ul>
            <p className='pt-[8rem] text-center text-[1.6rem] font-bold normal-case text-black md:hidden'>
              ©Apollon 2024 - All rights reserved
            </p>
          </div>
        </div>
        <div className='flex items-center justify-between py-[2rem]'>
          <p className='hidden normal-case md:block'>
            ©Apollon 2024 - All rights reserved
          </p>
          <div className='flex w-full justify-between px-[1rem] md:w-auto md:gap-[4rem] lg:gap-[5.5rem]'>
            <Link href={'https://www.facebook.com/apollon.uk'}>
              <i className='apollon-facebook1 text-[1.7rem] text-gray1' />
            </Link>
            <Link href={'https://twitter.com/apollon_ltd'}>
              <i className='apollon-twitter1 text-[1.6rem] text-gray1' />
            </Link>
            <Link href={'https://www.linkedin.com/company/apollon-uk'}>
              <i className='apollon-brandico_linkedin-rect text-[1.6rem] text-gray1' />
            </Link>
            <Link href={'https://www.instagram.com/apollon_uk'}>
              <i className='apollon-ant-design_instagram-filled text-[2rem] text-gray1' />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const ItemLink = ({
  path,
  title,
  className,
}: {
  path: string;
  title: string;
  className?: string;
}) => {
  return (
    <li className='py-[0.7rem] text-center last-of-type:pb-0 md:text-left xl:py-[0.6rem]'>
      <Link
        href={path}
        className={clsx(
          'text-[1.4rem] font-medium capitalize md:text-[1rem] xl:text-[1.6rem]',
          className
        )}
      >
        {title}
      </Link>
    </li>
  );
};
