import React from 'react';
import ActiveLink from '../../ActiveLink';
import styles from '../../Style.module.css';
import clsx from 'clsx';

const fontTextLinks = `md:py-[2.2rem] xl:py-[2.8rem] md:text-[1.2rem] 
                lg:text-[1.4rem] xl:text-[1.6rem] font-medium text-black hover:text-primary`;

const NavItem = ({
  title,
  href,
  onClick,
}: {
  title: string;
  href?: string;
  onClick?: () => void;
}) => {
  return (
    <li className='md:pr-[2rem] lg:pr-[2.6rem] xl:pr-[4.5rem] cursor-pointer'>
      {href ? (
        <ActiveLink activeClassName={styles['activeLink']} href={href}>
          <span className={clsx(fontTextLinks, 'capitalize')}>{title}</span>
        </ActiveLink>
      ) : (
        <span onClick={onClick} className={clsx(fontTextLinks, 'capitalize')}>
          {title}
        </span>
      )}
    </li>
  );
};

export default React.memo(NavItem);
