import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import Link from 'common/components/tags/link';
import clsx from 'clsx';

const NavItem = ({
  href,
  title,
  iconClassName,
  badge
}: {
  href: string;
  iconClassName: string;
  title: string;
  height?: number;
  width?: number;
  badge?:number
}) => {
  return (
    <li className={'border-b border-gray5'}>
      <Link href={href} className='flex items-center justify-between p-5'>
        <div className='flex items-center relative'>
          {Boolean(badge)&&<span className='absolute top-[3px] -right-6 bg-primary text-white w-5 h-5 rounded-full flex-center text-[1.2rem]'>{badge}</span>}
          <i
            className={clsx(
              'apollon-' + iconClassName,
              'text-[1.9rem] text-gray1',
            )}
          />
          <p className='ml-2 text-[1.6rem] font-medium text-gray1'>{title}</p>
        </div>
        <FiChevronRight className='text-gray1' size={16} />
      </Link>
    </li>
  );
};

export default NavItem;
