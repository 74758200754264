import React from 'react';
import { useWindowSize } from 'usehooks-ts';
import MobileNavItem from './NavItem';
import PricingLink from '../PricingLink';
import Avatar from 'common/components/UI/avatar/Avatar';
import { useProfileContext } from 'common/context/profile-context';
import Typography from 'common/components/tags/typography/Typography';
import clsx from 'clsx';
import Link from 'common/components/tags/link';
import { useModalContext } from 'common/context/modal-context';
import { useRouter } from 'next/router';

const NavItems = ({
  collapsible,
  pendingRequestsCount,
}: {
  collapsible: boolean;
  pendingRequestsCount?: number;
}) => {
  const modalCtx = useModalContext();
  const { width } = useWindowSize();
  const profileCtx = useProfileContext();
  const router = useRouter();

  let sulLinks = collapsible
    ? 'opacity-1 min-h-[420px] pb-4'
    : 'overflow-hidden h-0 p-0';

  const handleLogout = () => {
    modalCtx?.toggleModal('signOut');
  };

  const navList = [
    {
      title: 'add property',
      href: '/add-property',
      icon: 'apollon-key-square',
    },
    {
      title: 'dashboard',
      href: '/dashboard',
      icon: 'apollon-dashboard',
    },
    {
      title: 'Requests',
      href: '/sent-requests',
      icon: 'apollon-request',
      badge: pendingRequestsCount,
    },
    {
      title: 'Find Property',
      href: '/find-property',
      icon: 'apollon-newHome',
    },
    {
      title: 'help Centre',
      href: '/help/categories',
      icon: 'apollon-about-us',
    },
    {
      title: 'Blog',
      onClick: () => router.push('https://apollon.uk.com/blog'),
      icon: 'apollon-news',
    },
  ];

  return (
    <>
      {width < 768 && (
        <>
          {/* {!collapsible && !scroll && (
             <span className={"border-b border-gray5 block"} />
          )} */}
          <div
            className={clsx(
              'absolute left-0 right-0 z-[1] rounded-b-[3rem] bg-white px-5 duration-300',
              sulLinks
            )}
          >
            {profileCtx?.data && (
              <Link href='/profile' className='flex items-center py-4'>
                <Avatar
                  src={profileCtx.data.avatar?.downloadUrl}
                  alt={profileCtx.data.email}
                  wrapperClassName='w-[7.5rem] h-[7.5rem] mr-4'
                />
                <div>
                  {profileCtx.data?.name && (
                    <Typography
                      variant='h4-card'
                      color='primary'
                      className='pb-1'
                    >
                      {profileCtx.data?.name}
                    </Typography>
                  )}
                  <div className='flex items-center'>
                    <img src='/img/email.svg' alt='email' className='h-4 w-4' />
                    <p className='pl-1 text-black'>{profileCtx?.data?.email}</p>
                  </div>
                </div>
              </Link>
            )}
            <span className={clsx('block border-b border-gray5')} />
            <ul>
              {navList.map((item, index) => (
                <MobileNavItem key={index} {...item} />
              ))}
              <PricingLink />
              {!profileCtx?.data ? (
                <>
                  <span className='my-2 block border-b border-gray5' />
                  <MobileNavItem
                    title='Sign In'
                    href='/signin'
                    icon='apollon-sign-in'
                  />
                  <MobileNavItem
                    title='Sign up'
                    href='/signup'
                    icon='apollon-sign-out'
                  />
                </>
              ) : (
                <MobileNavItem
                  title='sign out'
                  onClick={handleLogout}
                  icon='apollon-sign-out'
                />
              )}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default NavItems;
