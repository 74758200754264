import React from 'react';
import ActiveLink from '../../ActiveLink';
import styles from '../../Style.module.css';
import clsx from 'clsx';

const NavItem = ({
  title,
  href,
  icon,
  onClick,
  badge,
}: {
  title: string;
  href?: string;
  onClick?: () => void;
  icon?: string;
  badge?: number;
}) => {
  return (
    <li>
      {href ? (
        <ActiveLink activeClassName={styles['activeLinkMobile']} href={href}>
          <span className='flex items-center py-4 text-black '>
            <i className={clsx(icon, 'pr-1 text-[1.8rem]')} />
            <div className='relative'>
              <span>{title}</span>
              {Boolean(badge) && (
                <span className='flex-center absolute top-[1px] -right-6 h-5 w-5 rounded-full bg-primary text-[1.2rem] text-white'>
                  {badge}
                </span>
              )}
            </div>
          </span>
        </ActiveLink>
      ) : (
        <span className='flex items-center py-5 text-black' onClick={onClick}>
          <i className={clsx(icon, 'pr-1 text-[1.8rem]')} />
          <span>{title}</span>
        </span>
      )}
    </li>
  );
};

export default React.memo(NavItem);
