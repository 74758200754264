import React from 'react';
import NavItem from './NavItem';
import PricingLink from '../PricingLink';
import { useRouter } from 'next/router';

const NavItems = () => {
  const router = useRouter();
  const navList = [
    {
      title: 'add property',
      href: '/add-property',
    },
    {
      title: 'Find Property',
      href: '/find-property',
    },
    {
      title: 'Blog',
      onClick: () => router.push('https://apollon.uk.com/blog'),
    },
    {
      title: 'help Centre',
      href: '/help/categories',
    },
  ];
  return (
    <nav className='hidden md:block'>
      <ul className='hidden items-center md:flex'>
        {navList.map((item, index) => (
          <NavItem key={index} {...item} />
        ))}
        <PricingLink />
      </ul>
    </nav>
  );
};

export default NavItems;
