import { useState, useEffect } from 'react';
import styles from '../Style.module.css';
import Overlay from 'common/components/UI/overlay';
import clsx from 'clsx';
import { useAuthContext } from 'common/context/auth-context';
import MobileNavItems from './mobile/NavItems';
import SignOutModal from '../../modals/SignOut-Modal';
import Logo from 'common/components/UI/Logo';
import useHttp from 'common/hooks/useHttp';
import NavItems from './desktop/NavItems';
import ProfileInfo from './desktop/ProfileInfo';

const Header = ({ className }: { className?: string }) => {
  const authCtx = useAuthContext();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [collapsible, setCollapsible] = useState<boolean>(false);
  const [scroll, setScroll] = useState<boolean>(false);
  const { sendRequest, loading } = useHttp();
  const [pendingRequestsCount, setPendingRequestsCount] = useState<number>(0);

  useEffect(() => {
    if (authCtx?.isLogin) {
      sendRequest({ url: '/property-requests/stats' }, onSuccess);
      fetchStat();
    }
  }, [authCtx?.isLogin]);

  const fetchStat = () => {
    setInterval(() => {
      sendRequest({ url: '/property-requests/stats' }, onSuccess);
    }, 10000);
  };

  const onSuccess = (res: Responsive) => {
    setPendingRequestsCount(res.data.totalNewChanges);
  };

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && collapsible) {
        setCollapsible(false);
      }
    };
    window.addEventListener('resize', hideMenu);
    return () => {
      window.addEventListener('resize', hideMenu);
    };
  });

  useEffect(() => {
    const listenScrollEvent = () => {
      if (window.scrollY > 20) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);

  const handleOverlay = () => {
    setCollapsible(false);
    setShowDropdown(false);
  };

  return (
    <>
      <header
        className={clsx(
          'sticky top-0 left-0 right-0 z-[997]',
          scroll ? 'border-b' : className,
          styles['header']
        )}
      >
        {/* link in desktop */}
        <div
          className={`container flex items-center justify-between py-4 md:py-0 ${
            collapsible ? 'bg-white' : ''
          }`}
        >
          <Logo />
          <NavItems />

          <div className='flex items-center'>
            <div
              className='cursor-pointer py-[1.1rem] pl-[1.1rem] duration-300 md:hidden'
              onClick={() => setCollapsible((old) => !old)}
            >
              {collapsible ? (
                <i className='apollon-close text-[2.3rem] font-bold text-primary' />
              ) : (
                // <img src="/img/close.svg" alt="close" />
                <img src='/img/hamburger.svg' alt='hamburger' />
              )}
            </div>
            {/* auth btns and google acount */}
            <ProfileInfo
              pendingRequestsCount={pendingRequestsCount}
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
            />
          </div>
        </div>
        {/* Mobile links */}
        <MobileNavItems
          collapsible={collapsible}
          pendingRequestsCount={pendingRequestsCount}
        />
      </header>

      <SignOutModal />
      <Overlay
        active={collapsible || showDropdown}
        onClick={handleOverlay}
        varint={collapsible ? 'dark' : 'light'}
      />
    </>
  );
};
export default Header;
