import React from 'react';
import { ReactNode } from 'react';
import style from './Style.module.css';
import clsx from 'clsx';
import Header from './header/Header';
// const Footer = dynamic(() => import("./Footer"), { suspense: true });
import Footer from './Footer';
import Loading from 'common/components/UI/loading/Loading';

export default function Layout({
  children,
  mainClassName,
  footerClassName,
  headerClassName,
  laoding,
  containerClassName,
  showHeader = true,
  showFooter = true,
}: {
  children: ReactNode;
  laoding?: boolean;
  mainClassName?: string;
  footerClassName?: string;
  containerClassName?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  headerClassName?: string;
}) {
  return (
    <>
      {laoding && <Loading />}
      <div
        className={clsx(
          laoding && 'h-[100vh] overflow-hidden',
          containerClassName
        )}
      >
        {showHeader && <Header className={headerClassName} />}
        <main className={clsx(style['main'], mainClassName)}>{children}</main>
        {showFooter && <Footer className={footerClassName} />}
      </div>
    </>
  );
}
