import React from 'react';
import Image from 'next/image';
import Typography from 'common/components/tags/typography/Typography';
import Button from 'common/components/tags/button/Button';
import { useModalContext } from 'common/context/modal-context';
import { removeItemLocalStorage } from 'common/utils/localStorage';
import { AccessTokenName, RefreshTokenName } from 'common/utils/constants';
import { useAuthContext } from 'common/context/auth-context';
import { useProfileContext } from 'common/context/profile-context';
import useHttp from 'common/hooks/useHttp';
import { googleLogout } from '@react-oauth/google';
import Modal from 'common/components/UI/modal';

import clsx from 'clsx';
export interface props {
  className?: string;
}

const SignOutModal = ({ className }: props) => {
  const modalCtx = useModalContext();
  const authCtx = useAuthContext();
  const profileCtx = useProfileContext();
  const { sendRequest, loading } = useHttp();

  const handleSignOut = () => {
    sendRequest({ url: '/auth/sign-out', method: 'POST' }, onSuccess);
  };

  const onSuccess = () => {
    authCtx?.logOut();
    modalCtx?.close();
    profileCtx?.resetData();
  };

  const handleCanceled = () => {
    modalCtx?.close();
  };

  return (
    <Modal
      size='sm'
      centered
      name='signOut'
      closeButton={false}
      className='w-full'
    >
      <div className={clsx('w-full', className)}>
        <Image src='/img/union.svg' alt='union' width={35} height={39} />
        <Typography variant='h4-card' className='pt-3 capitalize'>
          Sign Out!
        </Typography>
        <p className='text-[1.5rem] font-medium text-black md:pt-1 xl:pt-2'>
          Are you sure you want to sign out?
        </p>
        <div className='flex justify-between pt-4'>
          <Button
            label='sign out'
            className='mr-4 w-full'
            variant='outLine'
            onClick={handleSignOut}
            loading={loading}
          />
          <Button label='cancel' className='w-full' onClick={handleCanceled} />
        </div>
      </div>
    </Modal>
  );
};

export default SignOutModal;
